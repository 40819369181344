import axios from 'axios'
import Cookies from 'js-cookie'
import store from '@/store'
import { router } from '@/router'
import statusMiddleware from './statusMiddleware'
import errorMiddleware from './errorMiddleware'

const _api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
})
export const noAuthHttp = axios.create({ baseURL: process.env.VUE_APP_API_URL })

_api.interceptors.request.use((config) => {
  const token = Cookies.get('token')

  if (token) config.headers['Authorization'] = `Bearer ${token}`
  // XXX: redireciona para login se não tiver token (separation of concerns fail)
  else router.push('/login')

  return config
})

_api.interceptors.response.use(
  (response) => response,
  (err) => {
    statusMiddleware(err?.response?.status)
    errorMiddleware(err?.response?.data?.error)
    store.dispatch('apiManager/setApiError', err?.response?.data)
    return Promise.reject(err)
  }
)

noAuthHttp.interceptors.response.use(
  (response) => response,
  (err) => {
    statusMiddleware(err?.response?.status)
    store.dispatch('apiManager/setApiError', err?.response?.data)
    return Promise.reject(err)
  }
)

export default _api
