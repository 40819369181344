import api from '@/services/api'

export const integrations = {
  namespaced: true,

  state: () => ({
    integrations: {
      fieldview: null,
    },
  }),

  mutations: {
    integrations(state, { fieldview }) {
      state.integrations.fieldview = fieldview ?? null
    },
  },

  getters: {
    integrations: (state) => {
      return state.integrations
    },

    isIntegrated: (state) => {
      return state.integrations?.fieldview?.integrated ? true : false
    },
  },

  actions: {
    async fetchIntegrations({ commit }) {
      const { data } = await api.integrations.fetchIntegrations()

      if (!data) throw new Error('Fail to fetch integrations')

      if (data.fieldview) {
        await commit('integrations', data)
      } else {
        await commit('integrations', { fieldview: null })
      }
    },

    async disableFieldview({ dispatch }) {
      await api.integrations.disableFieldview()
      await dispatch('fetchIntegrations')
    },
  },
}
