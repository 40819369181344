export default {
  clickedButton_createGenetics_Planning_Wheat:
    'clickedButton_createGenetics_Planning_Wheat',
  clickedButton_createGenetics_Planning_Soy:
    'clickedButton_createGenetics_Planning_Soy',
  clickedButton_Register_genetics: 'clickedButton_Register_genetics',
  clickedFunction_Search_name: 'clickedFunction_Search_name',
  clickedFunction_Order: 'clickedFunction_Order',
  clickedFunction_Filter: 'clickedFunction_Filter',
  clickedFunction_Filter_clean: 'clickedFunction_Filter_clean',
}
