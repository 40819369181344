export default {
  namespaced: true,

  state: {
    maintenanceMode: false,
    apiError: null,
  },

  getters: {
    getApiError(state) {
      return state.apiError
    },
    isMaintenanceMode(state) {
      return state.maintenanceMode
    },
  },

  mutations: {
    setApiError(state, apiError) {
      state.apiError = apiError
    },
    toggleMaintenanceMode(state, shouldEnable) {
      state.maintenanceMode = shouldEnable
    },
  },

  actions: {
    toggleMaintenanceMode({ commit }, shouldEnable) {
      commit('toggleMaintenanceMode', shouldEnable)
    },
    setApiError({ commit }, err) {
      const formattedError = {
        code: err?.code,
        key: err?.key ?? 'exception.unexpected_error',
        context: err?.context,
      }
      commit('setApiError', formattedError)
    },
  },
}
