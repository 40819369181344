// FIX-ME: Trazer essas informações de um endpoint, somente onde for usar
// https://github.com/orgs/DigiFarmz/projects/4/views/2?filterQuery=sprint%3A%22Sprint+43%22+&pane=issue&itemId=58254949
const soybeanIcon = 'Soybean.png'

const wheatIcon = 'Wheat.png'

export const SOYBEAN_ID = 1
export const WHEAT_ID = 2

export const CROP = {
  SOYBEAN: 1,
  WHEAT: 2,
}
export const crops = [
  {
    id: CROP.SOYBEAN,
    name: 'crop.soybean',
    icon: soybeanIcon,
  },
  {
    id: CROP.WHEAT,
    name: 'crop.wheat',
    icon: wheatIcon,
  },
]

export const getCropNameById = (cropId) => {
  const currentCrop = crops.find(({ id }) => id == cropId) ?? null
  if (Object.keys(currentCrop).length) {
    return currentCrop.name
  }
  return ''
}

export const getCropIconById = (cropId) => {
  const currentCrop = crops.find(({ id }) => id == cropId) ?? null
  if (Object.keys(currentCrop).length) {
    return require(`/public/img/${currentCrop.icon}`)
  }
  return ''
}
