export const isInt = (n) => {
  return Number(n) === n && n % 1 === 0
}

export const isFloat = (n) => {
  return Number(n) == n && Number(n) % 1 !== 0
}

export const parseFloatNumber = (value) => {
  if (isFloat(value) || isInt(value)) {
    return value
  }
  if (value) {
    return parseFloat(value.replace(/,/g, '.'))
  }
  return null
}
