import api from '@/services/api'

export default {
  namespaced: true,

  state: {
    user: null,
    users: null,
    season: null,
    language: null,
    country: null,
  },
  getters: {
    currentUser(state) {
      return state.user
    },

    currentLanguage(state) {
      return state.language
    },

    users(state) {
      return state.users
    },

    areaExceededAccount(state) {
      return state?.user?.plan?.priority_level == 4 && state.user?.used_area
    },

    getCountry(state) {
      return state.country
    },

    getLanguage(state) {
      return state.user?.languageAccount
    },

    isBrazil(state) {
      return state.country == 1
    },

    isUSA(state) {
      return state.country == 3
    },

    isAdmin(state) {
      return state.user?.is_admin
    },

    isFreemiumPlan(state) {
      return (
        state?.user?.plan?.name == 'Freemium' || state?.user?.plan?.id === 5
      )
    },

    isPlanLevelOne(state) {
      return state?.user?.plan?.priority_level == 1
    },

    isPlanLevelTwo(state) {
      return state?.user?.plan?.priority_level == 2
    },

    isPlanLevelThree(state) {
      return state?.user?.plan?.priority_level == 3
    },

    isPlanLevelFour(state) {
      return state?.user?.plan?.priority_level == 4
    },

    contractedPlanLevel(state) {
      return state?.user?.plan.priority_level
    },
  },

  mutations: {
    setUsers(state, users) {
      state.users = users
    },

    setUser(state, user) {
      state.user = user
    },

    setLanguage(state, lang) {
      state.language = lang
    },

    setCountry(state, country) {
      state.country = country
    },
  },

  actions: {
    async fetchUserData({ commit }) {
      try {
        const { data } = await api.menu.usuario()
        commit('setUser', data)
        commit('setLanguage', data.language)
        commit('setCountry', data.account.country_id)
      } catch (e) {
        throw new Error(e)
      }
    },

    async changeLanguage({ commit }, language) {
      try {
        localStorage.setItem('language', language)
        commit('setLanguage', language)
      } catch (e) {
        throw new Error(e)
      }
    },

    async fetchUsers({ commit }) {
      try {
        const { data } = await api.users.all()
        commit('setUsers', data)
      } catch (e) {
        throw new Error(e)
      }
    },

    async deleteUser({ dispatch }, userId) {
      try {
        await api.users.delete(userId)
        dispatch('fetchUsers')
      } catch (e) {
        throw new Error(e)
      }
    },

    async changeUserStatus({ dispatch }, userId) {
      try {
        await api.users.active(userId)
        dispatch('fetchUsers')
      } catch (e) {
        throw new Error(e)
      }
    },
  },
}
