import moment from 'moment-timezone'
import i18n, { currentLanguage } from '@/language'
import dateTimeFormats from '@/language/date'

export function normalizeCurrency(value) {
  if (!value) return 0
  const rest = value.substring(0, value.length - 3)
  const cents = value.substring(value.length - 3)
  const restOnlyDigits = rest.replace(/[^\d]/g, '')
  const formattedCents = new RegExp(/,\d{1,2}/).test(cents)
    ? cents.replace(/,/g, '.')
    : cents
  return Number(`${restOnlyDigits}${formattedCents}`)
}

export function normalizeCoordinatesKML(coordinates) {
  const coordinatesValid = coordinates
    .replaceAll(/\d*\.*\d* /g, '')
    .replaceAll(/\d*\n/g, '')
    .replaceAll('\t', '')
    .replaceAll('\n', '')
    .split(',')
    .filter((val) => Boolean(Number(val)))

  if (coordinatesValid.length % 2 !== 0) {
    coordinatesValid.pop()
  }

  return coordinatesValid
}

const datetime = (date) =>
  moment.utc(date).tz(dateTimeFormats[currentLanguage()].timezone)

export function formatDayOfMonth(date) {
  return i18n.d(datetime(date), 'day_month')
}

export function formatDayDayOfMonth(date) {
  return i18n.d(datetime(date), 'weekday_day_month')
}

export function formatHour(date) {
  return i18n.d(datetime(date), 'time')
}
