import analytics from 'amplitude-js'
import events from './events'

function initialize(userId) {
  analytics.getInstance().init(process.env.VUE_APP_AMPLITUDE, userId)
}

function logEvent(nameEvent, parameters = null) {
  analytics.getInstance().logEvent(nameEvent, parameters)
}

function identifyUser(parameters = null) {
  analytics.getInstance().setUserProperties(parameters)
}

export { events, logEvent, initialize, identifyUser }
