const crops = [
  {
    id: 1,
    message_key: 'crop.soybean',
    name: 'Soybean',
  },
  {
    id: 2,
    message_key: 'crop.wheat',
    name: 'Wheat',
  },
  {
    id: 3,
    message_key: 'crop.corn',
    name: 'Corn',
  },
]

const cropsByName = crops.reduce((acc, crop) => {
  acc[String(crop.name).toLowerCase()] = crop
  return acc
}, {})

const assetOwners = [
  { id: 1, name: 'Brevant' },
  { id: 2, name: 'Dow AgroSciences' },
  { id: 3, name: 'Pioneer' },
  { id: 4, name: 'OR Melhoramento de Sementes' },
  { id: 5, name: 'Coodetec' },
  { id: 6, name: 'Biotrigo Genética' },
  { id: 7, name: 'Dono de Milho' },
]

const varieties = [
  // Soja
  {
    id: 1,
    name: '5D6215 IPRO',
    crop: cropsByName['soybean'],
    cycle: 132,
    gmr: 6.4,
    ogm: 'Sim',
    asset_owner: assetOwners[0].name,
    growth_habit: null,
    group: 6.4,
    min_population: 200,
    max_population: 300,
    germinative_power: 59,
    seed_vigor: 76,
    epoca_de_semeadura: {
      inicio: new Date().getDate() + 1,
      fim: new Date().getDate() + 90,
    },
  },
  {
    id: 2,
    name: '5D634 RR',
    crop: cropsByName['soybean'],
    cycle: 136,
    gmr: 6.3,
    ogm: 'Sim',
    asset_owner: assetOwners[1].name,
    growth_habit: null,
    group: 6.3,
    min_population: 220,
    max_population: 300,
    germinative_power: 80,
    seed_vigor: 97,
    epoca_de_semeadura: {
      inicio: new Date().getDate() + 1,
      fim: new Date().getDate() + 90,
    },
  },
  {
    id: 3,
    name: '95R30 IPRO',
    crop: cropsByName['soybean'],
    cycle: 123,
    gmr: 5.0,
    ogm: 'Sim',
    asset_owner: assetOwners[2].name,
    growth_habit: null,
    group: 5.0,
    min_population: 280,
    max_population: 300,
    germinative_power: 34,
    seed_vigor: 53,
    epoca_de_semeadura: {
      inicio: new Date().getDate() + 1,
      fim: new Date().getDate() + 90,
    },
  },
  {
    id: 4,
    name: '95R40 IPRO',
    crop: cropsByName['soybean'],
    cycle: 122,
    gmr: 5.4,
    ogm: 'Sim',
    asset_owner: assetOwners[2].name,
    growth_habit: null,
    group: 5.4,
    min_population: 250,
    max_population: 350,
    germinative_power: 99,
    seed_vigor: 99,
    epoca_de_semeadura: {
      inicio: new Date().getDate() + 1,
      fim: new Date().getDate() + 90,
    },
  },
  {
    id: 5,
    name: '95R51',
    crop: cropsByName['soybean'],
    cycle: 120,
    gmr: 5.3,
    ogm: 'Sim',
    asset_owner: assetOwners[2].name,
    growth_habit: null,
    group: 5.3,
    min_population: 320,
    max_population: 390,
    germinative_power: 74,
    seed_vigor: 11,
    epoca_de_semeadura: {
      inicio: new Date().getDate() + 1,
      fim: new Date().getDate() + 90,
    },
  },
  // Soja
  // Trigo
  {
    id: 6,
    name: 'Ametista',
    crop: cropsByName['wheat'],
    cycle: 125,
    gmr: null,
    ogm: 'Não',
    asset_owner: assetOwners[3].name,
    growth_habit: null,
    group: null,
    min_population: 330,
    max_population: 350,
    germinative_power: 1,
    seed_vigor: 1,
    epoca_de_semeadura: {
      inicio: new Date().getDate() + 1,
      fim: new Date().getDate() + 90,
    },
  },
  {
    id: 7,
    name: 'Anak',
    crop: cropsByName['wheat'],
    cycle: 124,
    gmr: null,
    ogm: 'Não',
    asset_owner: assetOwners[4].name,
    growth_habit: null,
    group: null,
    min_population: 360,
    max_population: 510,
    germinative_power: 12,
    seed_vigor: 26,
    epoca_de_semeadura: {
      inicio: new Date().getDate() + 1,
      fim: new Date().getDate() + 90,
    },
  },
  {
    id: 8,
    name: 'Biotrigo Sentinela',
    crop: cropsByName['wheat'],
    cycle: 148,
    gmr: null,
    ogm: 'Não',
    asset_owner: assetOwners[5].name,
    growth_habit: null,
    group: null,
    min_population: 275,
    max_population: 250,
    germinative_power: 93,
    seed_vigor: 78,
    epoca_de_semeadura: {
      inicio: new Date().getDate() + 1,
      fim: new Date().getDate() + 90,
    },
  },
  {
    id: 9,
    name: 'Biotrigo Talismã',
    crop: cropsByName['wheat'],
    cycle: 117,
    gmr: null,
    ogm: 'Não',
    asset_owner: assetOwners[5].name,
    growth_habit: null,
    group: null,
    min_population: 350,
    max_population: 330,
    germinative_power: 75,
    seed_vigor: 42,
    epoca_de_semeadura: {
      inicio: new Date().getDate() + 1,
      fim: new Date().getDate() + 90,
    },
  },
  {
    id: 10,
    name: 'Biotrigo Titan',
    crop: cropsByName['wheat'],
    cycle: 127,
    gmr: null,
    ogm: 'Não',
    asset_owner: assetOwners[5].name,
    growth_habit: null,
    group: null,
    min_population: 330,
    max_population: 300,
    germinative_power: 66,
    seed_vigor: 88,
    epoca_de_semeadura: {
      inicio: new Date().getDate() + 1,
      fim: new Date().getDate() + 90,
    },
  },
  // Trigo
  // Milho
  {
    id: 11,
    name: 'Milho 1',
    crop: cropsByName['corn'],
    cycle: 120,
    gmr: null,
    ogm: 'Não',
    asset_owner: assetOwners[6].name,
    growth_habit: null,
    group: null,
    min_population: 330,
    max_population: 300,
    germinative_power: 56,
    seed_vigor: 95,
    epoca_de_semeadura: {
      inicio: new Date().getDate() + 1,
      fim: new Date().getDate() + 90,
    },
  },
  {
    id: 12,
    name: 'Milho 2',
    crop: cropsByName['corn'],
    cycle: 120,
    gmr: null,
    ogm: 'Não',
    asset_owner: assetOwners[6].name,
    growth_habit: null,
    group: null,
    min_population: 330,
    max_population: 300,
    germinative_power: 77,
    seed_vigor: 62,
    epoca_de_semeadura: {
      inicio: new Date().getDate() + 1,
      fim: new Date().getDate() + 90,
    },
  },
  {
    id: 13,
    name: 'Milho 3',
    crop: cropsByName['corn'],
    cycle: 120,
    gmr: null,
    ogm: 'Não',
    asset_owner: assetOwners[6].name,
    growth_habit: null,
    group: null,
    min_population: 330,
    max_population: 300,
    germinative_power: 7,
    seed_vigor: 98,
    epoca_de_semeadura: {
      inicio: new Date().getDate() + 1,
      fim: new Date().getDate() + 90,
    },
  },
  {
    id: 14,
    name: 'Milho 4',
    crop: cropsByName['corn'],
    cycle: 120,
    gmr: null,
    ogm: 'Não',
    asset_owner: assetOwners[6].name,
    growth_habit: null,
    group: null,
    min_population: 330,
    max_population: 300,
    germinative_power: 38,
    seed_vigor: 55,
    epoca_de_semeadura: {
      inicio: new Date().getDate() + 1,
      fim: new Date().getDate() + 90,
    },
  },
  {
    id: 15,
    name: 'Milho 5',
    crop: cropsByName['corn'],
    cycle: 120,
    gmr: null,
    ogm: 'Não',
    asset_owner: assetOwners[6].name,
    growth_habit: null,
    group: null,
    min_population: 330,
    max_population: 300,
    germinative_power: 27,
    seed_vigor: 39,
    epoca_de_semeadura: {
      inicio: new Date().getDate() + 1,
      fim: new Date().getDate() + 90,
    },
  },
  // Milho
]

export { crops, varieties }
