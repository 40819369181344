import api from '@/services/api'

export const cultivares = {
  namespaced: true,

  state: () => ({
    fazenda: null,
    fazendas: [],
    cultivar: null,
    cultivares: [],
    cultivaresLoading: false,
    sementes: [],
  }),

  getters: {
    fazendas: (state) => {
      return state.fazendas
    },
    cultivares: (state) => {
      return state.cultivares
    },
    cultivaresLoading: (state) => {
      return state.cultivaresLoading
    },
    cultivar: (state) => {
      return state.cultivar
    },
    fazenda: (state) => {
      return state.fazenda
    },
    sementes: (state) => {
      return state.sementes
    },
    varietiesByCrop(state, getters, rootState) {
      const cropId = rootState.crops.currentCrop?.id
      return state.cultivares?.filter((variety) => variety.crop_id == cropId)
    },
  },
  mutations: {
    setCultivares(state, cultivares) {
      state.cultivares = cultivares
    },
    setCultivaresLoading(state, loading) {
      state.cultivaresLoading = loading
    },
    setCultivar(state, cultivar) {
      state.cultivar = cultivar
    },
    setFazendas(state, fazendas) {
      state.fazendas = fazendas
    },
    setFazenda(state, fazenda) {
      state.fazenda = fazenda
    },
    setSementes(state, sementes) {
      state.sementes = sementes
    },
  },

  actions: {
    async listFazendas({ commit }) {
      const response = await api.fazendas.all()

      if (!response) throw new Error('Erro ao buscar fazendas')

      await commit('setFazendas', response.data)
      return true
    },
    async excluirFazenda({ state }) {
      const response = await api.fazendas.delete(state.fazenda.id)

      if (!response) throw new Error('Erro ao buscar fazenda')

      return true
    },
    async fetchSeasonCropVarieties({ commit, rootState }, farmId) {
      if (!farmId) return
      const seasonCropId = rootState.seasonsCrops.currentSeasonCrop?.id
      await commit('setCultivaresLoading', true)
      const response = await api.cultivares.get(farmId, seasonCropId)
      await commit('setCultivaresLoading', false)

      if (!response) throw new Error('Erro ao buscar cultivares')

      await commit('setCultivares', response.data)
    },
    async excluirCultivar(storage, data) {
      const response = await api.cultivares.delete(data)

      if (!response) throw new Error('Erro ao buscar cultivar')

      return true
    },
    async getSeedsBySelectedCrop({ commit, rootState }, farmId) {
      if (!farmId) return
      const cropId = rootState.crops.currentCrop?.id
      const seasonCrop = rootState.seasonsCrops.currentSeasonCrop

      if (!cropId) throw new Error('Undefined current crop')

      const response = await api.sementes.getSeedsWithCultivares(
        farmId,
        cropId,
        seasonCrop?.id
      )
      await commit('setSementes', response.data)
    },
    async getNewestSeeds({ commit, rootState }, farmId) {
      const cropId = rootState.crops?.currentCrop?.id

      if (!cropId) throw new Error('Undefined current crop')

      const response = await api.sementes.getSeedsWithCultivares(farmId, cropId)

      await commit('setSementes', response.data)
    },
    async createVariety(storage, variety) {
      const response = await api.varieties.create(variety)

      return response
    },
    async modalSolicitacao(storage, cultivar) {
      const response = await api.cultivares.solicitacao(
        cultivar.cultivar,
        cultivar.msg
      )

      if (!response) throw new Error('Erro ao enviar solicitação')

      return true
    },
  },
}
