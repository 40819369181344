import storage from './storage'

function SeasonsCropsStorage(season_crop) {
  this.storageKey = 'seasons-crops'
  this.season_crop = season_crop
  this.cropId = null

  this.clear = function () {
    storage(this.storageKey).clear()
  }

  this.save = function () {
    const stored = this.getAll()
    return storage(this.storageKey).save({
      ...stored,
      [this.season_crop.crop_id]: this.season_crop,
    })
  }

  this.getAll = function () {
    return storage(this.storageKey).recover()
  }

  this.crop = function (cropId) {
    this.cropId = cropId
    return this
  }

  this.get = function () {
    if (!this.cropId) {
      return null
    }

    const stored = storage(this.storageKey).recover()

    if (stored) {
      return stored[this.cropId] ?? null
    }

    return null
  }
}

export default (seasons_crops) => new SeasonsCropsStorage(seasons_crops)
