function Storage(key) {
  this.key = key

  this.recover = function () {
    return JSON.parse(localStorage.getItem(this.key))
  }

  this.save = function (value) {
    localStorage.setItem(this.key, JSON.stringify(value))
    return value
  }

  this.clear = function () {
    localStorage.removeItem(this.key)
  }
}

export default (key) => new Storage(key)
