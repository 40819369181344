import moment from 'moment'
import { formatDate as dateFormat } from '@/language'

function PermissionError(permission) {
  this.name = 'PermissionError'
  this.message = `User has no permission: ${permission}`
  this.stack = new Error().stack
}
PermissionError.prototype = Object.create(Error.prototype)

export const errorsPrototypes = {
  permissionError: (permission) => new PermissionError(permission),
}

export default () => {
  Number.prototype.floatString = function () {
    return this.toString().replace('.', ',')
  }

  Number.prototype.toFixedNotRounded = function (decimals) {
    let number = this.toString()
    const dotIndex =
      number.indexOf('.') == -1 ? number.length : number.indexOf('.')
    number = number.slice(0, dotIndex + (decimals + 1))
    return Number(number)
  }

  String.prototype.formatDate = function () {
    return dateFormat(moment(this, 'YYYY-MM-DD hh:mm:ss'))
  }

  // Make a deep copy of an object
  Object.clone = function (originalObject) {
    return Object.assign({}, originalObject)
  }

  String.prototype.capitalize = function (isRestLowerCase = false) {
    if (typeof this !== 'string') return ''
    const rest = isRestLowerCase ? this.slice(1).toLowerCase() : this.slice(1)
    return this.charAt(0).toUpperCase() + rest
  }

  String.prototype.acronym = function () {
    return this.match(/\b(\w)/g).join('')
  }
}
