import api from '@/services/api'
import { UNITED_STATES } from '@/utils/countries'

export default {
  namespaced: true,

  state: {
    currentFarm: null,
    farm: null,
    farms: null,
  },

  getters: {
    currentFarm(state) {
      return state.currentFarm
    },

    currentFarmId(state) {
      return state.currentFarm?.id
    },

    farms(state) {
      return state.farms
    },

    hasNoFarms(state) {
      return state?.farms?.length === 0
    },

    enabledFarm(state) {
      return state.currentFarm?.enabled
    },

    isFarmFromUSA(state) {
      return state.currentFarm?.location?.country?.id == UNITED_STATES
    },
  },

  mutations: {
    setCurrentFarm(state, farm) {
      state.currentFarm = farm
    },

    setFarms(state, farms) {
      state.farms = farms
    },
  },

  actions: {
    async firstFarmLoad(
      { commit, state, dispatch },
      routeFarmId,
      enableFetchFarms = true
    ) {
      if (enableFetchFarms) {
        await dispatch('fetchFarms')
      }

      if (!state.farms) {
        return
      }

      if (!state.currentFarm && !routeFarmId) {
        commit('setCurrentFarm', state.farms[0])
        return
      }

      if (!state.currentFarm && routeFarmId) {
        const farm =
          state.farms.find((farm) => farm.id == routeFarmId) ?? state.farms[0]

        commit('setCurrentFarm', farm)
        return
      }
    },

    setFarm({ commit, state }, farmId) {
      const farm = state.farms.find((farm) => farm.id == farmId)
      commit('setCurrentFarm', farm)
    },

    async fetchFarms({ state, commit, getters, dispatch }, routeFarmId) {
      const { data } = await api.fazendas.getFarms(
        'enabled:desc,created_at:desc'
      )

      if (!data) {
        throw new Error('Erro ao buscar fazendas')
      }
      commit('setFarms', data)

      if (getters.currentFarmId) {
        const currentFarm = state.farms.find(
          (farm) => farm.id == getters.currentFarmId
        )

        if (currentFarm) {
          commit('setCurrentFarm', currentFarm)
          return
        }
        await dispatch('firstFarmLoad', routeFarmId, false)
      }
    },

    async deleteFarm(
      { dispatch, getters, commit },
      { routeFarmId, excludingFarmId }
    ) {
      await api.fazendas.delete(excludingFarmId)

      if (getters.currentFarmId == excludingFarmId) {
        commit('setCurrentFarm', null)
      }

      commit(
        'setFarms',
        getters.farms.filter(({ id }) => id != excludingFarmId)
      )

      await dispatch('firstFarmLoad', routeFarmId)
    },

    async enableDisableFarm({ dispatch }, { farmId }) {
      await api.fazendas.enableDisable(farmId)

      await dispatch('firstFarmLoad')
    },
  },
}
