// eslint-disable-next-line no-useless-escape
const queryValueRegex = /^[a-zA-Z0-9_\-]+=[a-zA-Z0-9_\-]+$/
const isValidQueryValue = (query) => queryValueRegex.test(query)

export const queryBuilder = (...queries) => {
  const queryCompleted = queries.reduce((acc, newQuery, index) => {
    if (!newQuery) {
      return acc
    }
    const query = newQuery.trim()

    query.split('&').forEach((q) => {
      if (!isValidQueryValue(q)) {
        throw new Error(`Invalid query value: ${q}`)
      }
    })

    if (index === 0) {
      return `?${query}`
    }

    return `${acc}&${query}`
  }, '')

  return queryCompleted
}

export const farmIdQuery = (id) => (id ? `farm_id=${id}` : '')
export const cropIdQuery = (id) => (id ? `crop_id=${id}` : '')
export const seasonCropIdQuery = (id) => (id ? `season_crop_id=${id}` : '')

export const startPeriodQuery = (date) => (date ? `start_period=${date}` : '')
export const endPeriodQuery = (date) => (date ? `end_period=${date}` : '')
export const periodQuery = (start, end) =>
  queryBuilder(startPeriodQuery(start), endPeriodQuery(end)).substring(1)
