import api from '@/services/api'

export default {
  namespaced: true,

  state: {
    limit: 5,
    usedLimit: 0,
  },

  getters: {
    limit: (state) => {
      return state.limit
    },
    usedLimit: (state) => {
      return state.usedLimit
    },
    progressOfUse: (state) => {
      return (state.usedLimit * 100) / state.limit
    },
  },

  mutations: {
    setLimit(state, limit) {
      state.limit = limit
    },
    setUsed(state, usedLimit) {
      state.usedLimit = usedLimit
    },
  },

  actions: {
    async fetchFreemiumLimit({ commit }) {
      try {
        const { data } = await api.freemiumManage.get()

        commit('setLimit', data.limit)
        commit('setUsed', data.used)
      } catch ($e) {
        throw new Error('Error when fetching user limit')
      }
    },
    async consumeFreemiumLimit({ commit }) {
      try {
        const { data } = await api.freemiumManage.set()
        commit('setUsed', data.used)
      } catch ($e) {
        throw new Error('Error when consuming user limit')
      }
    },
  },
}
