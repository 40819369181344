class ApiError extends Error {
  constructor(status, message) {
    super(message)
    this.name = 'ApiError'
    this.status = status
  }
}

export function APIFailureWrapper({
  content,
  errorMessage = 'Erro genérico',
  specificErrors = [],
  failureRate = 0,
}) {
  const chance = Math.floor(Math.random() * 100)
  if (failureRate > chance) {
    if (specificErrors.length) {
      const error =
        specificErrors[Math.floor(Math.random() * specificErrors.length)]
      throw new ApiError(error.status, error.message)
    } else {
      throw new ApiError(400, errorMessage)
    }
  }
  return content
}

export function mockFlag(params, state = 'on') {
  if (state === 'on') {
    return params
  }
  return { method: params.method, url: '', result: params.result }
}
