import { PARAGUAY, BRAZIL, UNITED_STATES } from './countries'

export const getTermsOfUse = (countryId) => {
  if (countryId == BRAZIL) {
    return 'DigiFarmz_Termos_de_Uso_e_Servico.pdf'
  }
  if (countryId == PARAGUAY) {
    return 'DigiFarmz_Termos_de_Uso_e_Servico_es.pdf'
  }
  if (countryId == UNITED_STATES) {
    return 'DigiFarmz_Terms_of_Use.pdf'
  }
  return 'DigiFarmz_Terms_of_Use.pdf'
}
