import api from '@/services/api'

export const execucoes = {
  namespaced: true,

  state: () => ({
    fazenda: null,
    semeadura: null,
    semeaduras: [],
    cultivar: null,
    severidades: [],
    programa: null,
    programasPlanejados: [],
    execucoes: [],
    meteorologia: null,
    isLoadingExecucoes: false,
    isLoadingIrrigacoes: false,
    irrigacoes: null,
  }),

  mutations: {
    setFazendaId(state, fazendaId) {
      state.fazenda = { id: fazendaId }
    },

    setFazenda(state, fazenda) {
      state.fazenda = fazenda
    },

    setSemeaduraId(state, semeaduraId) {
      state.semeadura = { id: semeaduraId }
    },

    setSemeadura(state, semeadura) {
      state.semeadura = semeadura
    },

    setSemeaduras(state, semeaduras) {
      state.semeaduras = semeaduras
    },

    setCultivarId(state, cultivarId) {
      state.cultivar = { id: cultivarId }
    },

    setCultivar(state, cultivar) {
      state.cultivar = cultivar
    },

    setSeveridades(state, severidades) {
      state.severidades = severidades
    },

    setPrograma(state, programa) {
      state.programa = programa
    },

    setProgramasPlanejados(state, programasPlanejados) {
      state.programasPlanejados = programasPlanejados
    },

    setExecucoes(state, execucoes) {
      state.execucoes = execucoes
    },

    removeExecucao(state, execucaoId) {
      state.execucoes = state.execucoes.filter(
        (execucao) => execucao.id != execucaoId
      )
    },

    setMeteorologia(state, meteorologia) {
      state.meteorologia = meteorologia
    },

    isLoadingExecucoes(state, status) {
      state.isLoadingExecucoes = status
    },

    isLoadingIrrigacoes(state, status) {
      state.isLoadingIrrigacoes = status
    },

    setIrrigacoes(state, irrigacoes) {
      state.irrigacoes = irrigacoes
    },
  },

  getters: {
    fazenda: (state) => {
      return state.fazenda
    },

    semeaduras: (state) => {
      return state.semeaduras
    },

    semeadura: (state) => {
      return state.semeadura
    },

    cultivar: (state) => {
      return state.cultivar
    },

    severidades: (state) => {
      return state.severidades
    },

    meteorologia: (state) => {
      return state.meteorologia
    },

    programa: (state) => {
      return state.programa
    },

    programasPlanejados: (state) => {
      return state.programasPlanejados
    },

    execucoes: (state) => {
      return state.execucoes
    },

    maxDoencas: () => {
      return process.env.VUE_APP_MAX_DOENCAS_PRIORIDADES
    },

    isLoadingExecucoes: (state) => {
      return state.isLoadingExecucoes
    },

    isLoadingIrrigacoes: (state) => {
      return state.isLoadingIrrigacoes
    },

    irrigacoes: (state) => {
      return state.irrigacoes
    },

    nextSpraying: (state) => {
      const notAppliedSprayings = state.execucoes?.filter(
        (execucao) => !execucao?.data_realizada
      )
      return notAppliedSprayings.length > 0 ? notAppliedSprayings[0] : null
    },
  },

  actions: {
    async loadFazenda({ commit, state }) {
      const { data } = await api.fazendas.getWithInclude(state.fazenda.id, [
        'severidades',
        'meteorologia7dias',
      ])

      if (!data) throw new Error('Fail to load Fazenda')

      commit('setFazenda', data)
      commit('setSeveridades', data.severidades)
      commit('setMeteorologia', data.meteorologia7dias)
    },

    async loadWeather({ commit }, farmId) {
      const { data } = await api.fazendas.clima(farmId)

      if (!data) throw new Error('Fail to load wheather')

      commit('setMeteorologia', data)
    },

    async loadSemeaduras({ commit, state, dispatch, rootState }, farmId) {
      const { start_period, end_period } =
        rootState.seasonsCrops.currentSeasonCrop

      const { data } = await api.semeaduras.findByFazenda(
        farmId,
        ['semente', 'cultivar_regiao_parametros', 'colheita', 'status'],
        start_period,
        end_period
      )

      if (!data || data.length == 0)
        throw new Error('Falha ao carregar semeaduras')

      await commit('setSemeaduras', data)

      const semeadura = data.find(
        (semeadura) => semeadura.id == state.semeadura.id
      )

      if (!semeadura) throw new Error('Semeadura atual não encontrada')

      commit('setSemeadura', semeadura)
      await dispatch('crops/setCurrentCrop', semeadura.crop_id, { root: true })
      commit('setCultivarId', semeadura.cultivar_id)
    },

    async loadPrograma({ commit, state }) {
      const { data } = await api.programas.findBySemeadura(state.semeadura?.id)

      // TODO: move this logic to the page or component layer
      if (
        !data?.length ||
        (!data[0]?.programa && !data[0]?.execucoes?.length)
      ) {
        commit('setPrograma', null)
        commit('setExecucoes', null)
      } else {
        commit('setPrograma', data[0].programa)
        commit('setExecucoes', data[0].execucoes)
      }
    },

    async loadProgramasPlanejados({ commit, state, rootGetters }) {
      const { start_period, end_period } =
        rootGetters['seasonsCrops/activePeriod']

      const { data } = await api.programas.findByCultivar(
        state.cultivar.id,
        start_period,
        end_period
      )

      commit('setProgramasPlanejados', data)
    },

    async importPrograma({ state, dispatch }, programaPlanejado) {
      if (!programaPlanejado) throw new Error('Programa indefinido')

      await api.programas.importToSemeadura(
        programaPlanejado.id,
        state.semeadura.id
      )

      await dispatch('loadPrograma')
    },

    async createPrograma({ state, dispatch }, programaNovo) {
      programaNovo.semeadura_id = state.semeadura.id
      programaNovo.data_prevista = state.semeadura.data_semeadura

      await api.programas.create(programaNovo)

      await dispatch('loadPrograma')
    },

    async deletePrograma({ state, commit }) {
      if (!state.programa) throw new Error('Programa indefinido')

      await api.programas.delete(state.programa.id)
      commit('setPrograma', null)
    },

    async deleteExecucao({ commit, dispatch }, execucao) {
      if (!execucao) throw new Error('Execucao indefinida')

      await api.programas.execucoes.delete(execucao)

      // remove execucao da lista (optimistic)
      commit('removeExecucao', execucao.id)

      await dispatch('loadPrograma')
    },

    async changeSemeadura({ commit, dispatch }, semeadura) {
      await commit('setSemeadura', semeadura)
      await commit('setCultivarId', semeadura.cultivar_id)
      await dispatch('crops/setCurrentCrop', semeadura.crop_id, { root: true })

      await dispatch('loadPrograma')
    },

    async applySpray({ dispatch, commit }, { programId, ordinal, applied_at }) {
      await commit('isLoadingExecucoes', true)

      await api.programas.execucoes.applySpray({
        programId,
        ordinal,
        applied_at,
      })
      await dispatch('loadPrograma')

      await commit('isLoadingExecucoes', false)
    },

    async unapplySpray({ dispatch, commit }, { programId, ordinal }) {
      await commit('isLoadingExecucoes', true)

      await api.programas.execucoes.unapplySpray({
        programId,
        ordinal,
      })
      await dispatch('loadPrograma')

      await commit('isLoadingExecucoes', false)
    },

    async loadIrrigacoesSemeadura({ commit, state }) {
      const { data } = await api.irrigacoes.get(state.semeadura)

      if (!data) throw new Error('Fail to load Semeadura')

      await commit('isLoadingIrrigacoes', true)
      await commit('setIrrigacoes', data)
      await commit('isLoadingIrrigacoes', false)
    },

    async createIrrigacaoSemeadura({ state, commit, dispatch }, irrigacao) {
      if (!irrigacao) throw new Error('Irrigacao indefinido')

      irrigacao.semeadura_id = state.semeadura.id

      await commit('isLoadingExecucoes', true)
      await commit('isLoadingIrrigacoes', true)

      await api.irrigacoes.create(irrigacao)
      await dispatch('loadPrograma')
      await dispatch('loadIrrigacoesSemeadura')

      await commit('isLoadingExecucoes', false)
      await commit('isLoadingIrrigacoes', false)
    },

    async updateIrrigacaoSemeadura({ state, commit, dispatch }, irrigacao) {
      if (!irrigacao) throw new Error('Irrigacao indefinida')

      irrigacao.semeadura_id = state.semeadura.id

      await commit('isLoadingExecucoes', true)
      await commit('isLoadingIrrigacoes', true)

      await api.irrigacoes.update(irrigacao)

      await dispatch('loadPrograma')
      await dispatch('loadIrrigacoesSemeadura')

      await commit('isLoadingExecucoes', false)
      await commit('isLoadingIrrigacoes', false)
    },

    async deleteIrrigacaoSemeadura({ commit, dispatch }, id) {
      if (!id) throw new Error('Irrigacao indefinida')

      await commit('isLoadingExecucoes', true)
      await commit('isLoadingIrrigacoes', true)

      await api.irrigacoes.delete(id)

      await dispatch('loadPrograma')
      await dispatch('loadIrrigacoesSemeadura')

      await commit('isLoadingExecucoes', false)
      await commit('isLoadingIrrigacoes', false)
    },
  },
}
