import { fields } from '@/modules/plot/mock/fieldsSeeds'
import { sowings } from '@/modules/sowing/mock/sowingSeeds'
import moment from 'moment'

const farms = [
  {
    name: 'Fazenda Com Talhões',
    account_id: 1,
    active_sowing: String(
      sowings.filter((sowing) => {
        const fieldIds = fields.map((field) => {
          if (field.fazenda_id == 1) return field.id
        })
        return fieldIds.includes(sowing.talhao_id)
      }).length
    ),
    bag_price: [
      {
        crop_id: 1,
        currency_id: 2,
        price: 150,
      },
      { crop_id: 2, currency_id: 2, price: 75 },
    ],
    closets_applications: '00',
    enabled: true,
    id: 1,
    location: {
      city: {
        id: 4612,
        name: 'Alegrete',
      },
      country: {
        acronym: 'BR',
        id: 1,
        name: 'Brasil',
        latitude: '-29.782415680663',
        longitude: '-55.806706269753',
      },
      state: {
        acronym: 'RS',
        id: 21,
        name: 'Rio Grande do Sul',
      },
    },
    total_area: '150',
    total_plots: fields.filter((field) => field.fazenda_id === 1),
  },
  {
    name: 'Fazenda Com 1 Talhão',
    account_id: 1,
    active_sowing: String(
      sowings.filter((sowing) => {
        const fieldIds = fields.map((field) => {
          if (field.fazenda_id == 2) return field.id
        })
        return fieldIds.includes(sowing.talhao_id)
      }).length
    ),
    bag_price: [
      {
        crop_id: 1,
        currency_id: 2,
        price: 150,
      },
      { crop_id: 2, currency_id: 2, price: 75 },
    ],
    closets_applications: '00',
    enabled: true,
    id: 2,
    location: {
      city: {
        id: 4612,
        name: 'Alegrete',
      },
      country: {
        acronym: 'BR',
        id: 1,
        name: 'Brasil',
        latitude: '-29.782415680663',
        longitude: '-55.806706269753',
      },
      state: {
        acronym: 'RS',
        id: 21,
        name: 'Rio Grande do Sul',
      },
    },
    total_area: '200',
    total_plots: fields.filter((field) => field.fazenda_id === 2),
  },
  {
    name: 'Fazenda Sem Talhões',
    account_id: 1,
    active_sowing: String(
      sowings.filter((sowing) => {
        const fieldIds = fields.map((field) => {
          if (field.fazenda_id == 2) return field.id
        })
        return fieldIds.includes(sowing.talhao_id)
      }).length
    ),
    bag_price: [
      {
        crop_id: 1,
        currency_id: 2,
        price: 150,
      },
      { crop_id: 2, currency_id: 2, price: 75 },
    ],
    closets_applications: '00',
    enabled: true,
    id: 3,
    location: {
      city: {
        id: 4612,
        name: 'Alegrete',
      },
      country: {
        acronym: 'BR',
        id: 1,
        name: 'Brasil',
        latitude: '-29.782415680663',
        longitude: '-55.806706269753',
      },
      state: {
        acronym: 'RS',
        id: 21,
        name: 'Rio Grande do Sul',
      },
    },
    total_area: '200',
    total_plots: fields.filter((field) => field.fazenda_id === 2),
  },
  {
    name: 'Fazenda Desativada',
    account_id: 1,
    active_sowing: String(
      sowings.filter((sowing) => {
        const fieldIds = fields.map((field) => {
          if (field.fazenda_id == 3) return field.id
        })
        return fieldIds.includes(sowing.talhao_id)
      }).length
    ),
    bag_price: [
      {
        crop_id: 1,
        currency_id: 2,
        price: 200,
      },
      { crop_id: 2, currency_id: 2, price: 375 },
    ],
    closets_applications: '00',
    enabled: false,
    id: 4,
    location: {
      city: {
        id: 4612,
        name: 'Alegrete',
      },
      country: {
        acronym: 'BR',
        id: 1,
        name: 'Brasil',
        latitude: '-29.782415680663',
        longitude: '-55.806706269753',
      },
      state: {
        acronym: 'RS',
        id: 21,
        name: 'Rio Grande do Sul',
      },
    },
    total_area: '100',
    total_plots: fields.filter((field) => field.fazenda_id === 3),
  },
]

const farmClima = {
  id: farms[0].id,
  nome: farms[0].name,
  prec_mm_dia: [
    [moment().format('YYYY-MM-DD HH:mm:ss'), 4],
    [moment().add(1, 'days').format('YYYY-MM-DD HH:mm:ss'), 3],
    [moment().add(2, 'days').format('YYYY-MM-DD HH:mm:ss'), 2],
    [moment().add(3, 'days').format('YYYY-MM-DD HH:mm:ss'), 1],
    [moment().add(4, 'days').format('YYYY-MM-DD HH:mm:ss'), 0],
  ],
  temp_max_min_dia: [
    [28, 14, moment().format('YYYY-MM-DD')],
    [28, 16, moment().add(1, 'days').format('YYYY-MM-DD')],
    [28, 18, moment().add(2, 'days').format('YYYY-MM-DD')],
    [30, 18, moment().add(3, 'days').format('YYYY-MM-DD')],
    [31, 18, moment().add(4, 'days').format('YYYY-MM-DD')],
  ],
  prec_mm_hora: [
    [moment().format('HH:mm'), 0],
    [moment().add(1, 'hour').format('HH:mm'), 1],
    [moment().add(2, 'hour').format('HH:mm'), 2],
    [moment().add(3, 'hour').format('HH:mm'), 0],
    [moment().add(4, 'hour').format('HH:mm'), 1],
    [moment().add(5, 'hour').format('HH:mm'), 0],
  ],
  time_zone: 'America/Sao_Paulo',
}

const farmWeather = [
  {
    date: moment().format('YYYY-MM-DD'),
    dew_point_temperature: 4,
    wind_speed: 3.1,
    temperature: {
      current: 27,
      max: 28,
      min: 14,
    },
    hourly: {
      amount: 4,
      precipitation: [
        {
          time: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 16,
        },
        {
          time: moment()
            .startOf('day')
            .add(1, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 15,
        },
        {
          time: moment()
            .startOf('day')
            .add(2, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 15,
        },
        {
          time: moment()
            .startOf('day')
            .add(3, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 14,
        },
        {
          time: moment()
            .startOf('day')
            .add(4, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 14,
        },
        {
          time: moment()
            .startOf('day')
            .add(5, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 14,
        },
        {
          time: moment()
            .startOf('day')
            .add(6, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 14,
        },
        {
          time: moment()
            .startOf('day')
            .add(7, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 14,
        },
        {
          time: moment()
            .startOf('day')
            .add(8, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 17,
        },
        {
          time: moment()
            .startOf('day')
            .add(9, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 21,
        },
        {
          time: moment()
            .startOf('day')
            .add(10, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 23,
        },
        {
          time: moment()
            .startOf('day')
            .add(11, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 24,
        },
        {
          time: moment()
            .startOf('day')
            .add(12, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 25,
        },
        {
          time: moment()
            .startOf('day')
            .add(13, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 27,
        },
        {
          time: moment()
            .startOf('day')
            .add(14, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 28,
        },
        {
          time: moment()
            .startOf('day')
            .add(15, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 28,
        },
        {
          time: moment()
            .startOf('day')
            .add(16, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 27,
        },
        {
          time: moment()
            .startOf('day')
            .add(17, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 26,
        },
        {
          time: moment()
            .startOf('day')
            .add(18, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0.2,
          temperature: 21,
        },
        {
          time: moment()
            .startOf('day')
            .add(19, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0.8,
          temperature: 19,
        },
        {
          time: moment()
            .startOf('day')
            .add(20, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 1,
          temperature: 18,
        },
        {
          time: moment()
            .startOf('day')
            .add(21, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 2,
          temperature: 18,
        },
      ],
    },
    soil_moisture: null,
    precipitation: {
      amount: 0,
      hourly: [
        {
          time: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 16,
        },
        {
          time: moment()
            .startOf('day')
            .add(1, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 15,
        },
        {
          time: moment()
            .startOf('day')
            .add(2, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 15,
        },
        {
          time: moment()
            .startOf('day')
            .add(3, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 14,
        },
        {
          time: moment()
            .startOf('day')
            .add(4, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 14,
        },
        {
          time: moment()
            .startOf('day')
            .add(5, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 14,
        },
        {
          time: moment()
            .startOf('day')
            .add(6, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 14,
        },
        {
          time: moment()
            .startOf('day')
            .add(7, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 14,
        },
        {
          time: moment()
            .startOf('day')
            .add(8, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 17,
        },
        {
          time: moment()
            .startOf('day')
            .add(9, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 21,
        },
        {
          time: moment()
            .startOf('day')
            .add(10, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 23,
        },
        {
          time: moment()
            .startOf('day')
            .add(11, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 24,
        },
        {
          time: moment()
            .startOf('day')
            .add(12, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 25,
        },
        {
          time: moment()
            .startOf('day')
            .add(13, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 27,
        },
        {
          time: moment()
            .startOf('day')
            .add(14, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 28,
        },
        {
          time: moment()
            .startOf('day')
            .add(15, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 28,
        },
        {
          time: moment()
            .startOf('day')
            .add(16, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 27,
        },
        {
          time: moment()
            .startOf('day')
            .add(17, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 26,
        },
        {
          time: moment()
            .startOf('day')
            .add(18, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0.2,
          temperature: 21,
        },
        {
          time: moment()
            .startOf('day')
            .add(19, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0.8,
          temperature: 19,
        },
        {
          time: moment()
            .startOf('day')
            .add(20, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 1,
          temperature: 18,
        },
        {
          time: moment()
            .startOf('day')
            .add(21, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 2,
          temperature: 18,
        },
      ],
    },
  },
  {
    date: moment().add(1, 'days').format('YYYY-MM-DD'),
    dew_point_temperature: 8,
    wind_speed: 1.5,
    temperature: {
      current: 20,
      max: 28,
      min: 16,
    },
    hourly: {
      amount: 6,
      precipitation: [
        {
          time: moment()
            .startOf('day')
            .add(1, 'days')
            .add(3, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 4,
          temperature: 16,
        },
        {
          time: moment()
            .startOf('day')
            .add(1, 'days')
            .add(9, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 2,
          temperature: 21,
        },
        {
          time: moment()
            .startOf('day')
            .add(1, 'days')
            .add(15, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 28,
        },
        {
          time: moment()
            .startOf('day')
            .add(1, 'days')
            .add(21, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 20,
        },
      ],
    },
    soil_moisture: null,
    precipitation: {
      amount: 0,
      hourly: [
        {
          time: moment()
            .startOf('day')
            .add(1, 'days')
            .add(3, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 4,
          temperature: 16,
        },
        {
          time: moment()
            .startOf('day')
            .add(1, 'days')
            .add(9, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 2,
          temperature: 21,
        },
        {
          time: moment()
            .startOf('day')
            .add(1, 'days')
            .add(15, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 28,
        },
        {
          time: moment()
            .startOf('day')
            .add(1, 'days')
            .add(21, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 20,
        },
      ],
    },
  },
  {
    date: moment().add(2, 'days').format('YYYY-MM-DD'),
    dew_point_temperature: 8,
    wind_speed: 2.1,
    temperature: {
      current: 21,
      max: 28,
      min: 18,
    },
    hourly: {
      amount: 0,
      precipitation: [
        {
          time: moment()
            .startOf('day')
            .add(2, 'days')
            .add(3, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 18,
        },
        {
          time: moment()
            .startOf('day')
            .add(2, 'days')
            .add(9, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 21,
        },
        {
          time: moment()
            .startOf('day')
            .add(2, 'days')
            .add(15, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 28,
        },
        {
          time: moment()
            .startOf('day')
            .add(2, 'days')
            .add(21, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 21,
        },
      ],
    },
    soil_moisture: null,
    precipitation: {
      amount: 0,
      hourly: [
        {
          time: moment()
            .startOf('day')
            .add(2, 'days')
            .add(3, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 18,
        },
        {
          time: moment()
            .startOf('day')
            .add(2, 'days')
            .add(9, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 21,
        },
        {
          time: moment()
            .startOf('day')
            .add(2, 'days')
            .add(15, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 28,
        },
        {
          time: moment()
            .startOf('day')
            .add(2, 'days')
            .add(21, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 21,
        },
      ],
    },
  },
  {
    date: moment().add(3, 'days').format('YYYY-MM-DD'),
    dew_point_temperature: 6,
    wind_speed: 2.1,
    temperature: {
      current: 21,
      max: 30,
      min: 18,
    },
    hourly: {
      amount: 0,
      precipitation: [
        {
          time: moment()
            .startOf('day')
            .add(3, 'days')
            .add(3, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 18,
        },
        {
          time: moment()
            .startOf('day')
            .add(3, 'days')
            .add(9, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 21,
        },
        {
          time: moment()
            .startOf('day')
            .add(3, 'days')
            .add(15, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 30,
        },
        {
          time: moment()
            .startOf('day')
            .add(3, 'days')
            .add(21, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 21,
        },
      ],
    },
    soil_moisture: null,
    precipitation: {
      amount: 0,
      hourly: [
        {
          time: moment()
            .startOf('day')
            .add(3, 'days')
            .add(3, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 18,
        },
        {
          time: moment()
            .startOf('day')
            .add(3, 'days')
            .add(9, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 21,
        },
        {
          time: moment()
            .startOf('day')
            .add(3, 'days')
            .add(15, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 30,
        },
        {
          time: moment()
            .startOf('day')
            .add(3, 'days')
            .add(21, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 21,
        },
      ],
    },
  },
  {
    date: moment().add(4, 'days').format('YYYY-MM-DD'),
    dew_point_temperature: 4,
    wind_speed: 2.1,
    temperature: {
      current: 21,
      max: 31,
      min: 18,
    },
    hourly: {
      amount: 0,
      precipitation: [
        {
          time: moment()
            .startOf('day')
            .add(4, 'days')
            .add(3, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 18,
        },
        {
          time: moment()
            .startOf('day')
            .add(4, 'days')
            .add(9, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 24,
        },
        {
          time: moment()
            .startOf('day')
            .add(4, 'days')
            .add(15, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 31,
        },
        {
          time: moment()
            .startOf('day')
            .add(4, 'days')
            .add(21, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 21,
        },
      ],
    },
    soil_moisture: null,
    precipitation: {
      amount: 0,
      hourly: [
        {
          time: moment()
            .startOf('day')
            .add(4, 'days')
            .add(3, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 18,
        },
        {
          time: moment()
            .startOf('day')
            .add(4, 'days')
            .add(9, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 24,
        },
        {
          time: moment()
            .startOf('day')
            .add(4, 'days')
            .add(15, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 31,
        },
        {
          time: moment()
            .startOf('day')
            .add(4, 'days')
            .add(21, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 21,
        },
      ],
    },
  },
  {
    date: moment().add(5, 'days').format('YYYY-MM-DD'),
    dew_point_temperature: 9,
    wind_speed: 2.6,
    temperature: {
      current: 22,
      max: 28,
      min: 19,
    },
    hourly: {
      amount: 0,
      precipitation: [
        {
          time: moment()
            .startOf('day')
            .add(5, 'days')
            .add(3, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 19,
        },
        {
          time: moment()
            .startOf('day')
            .add(5, 'days')
            .add(9, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 21,
        },
        {
          time: moment()
            .startOf('day')
            .add(5, 'days')
            .add(15, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 28,
        },
        {
          time: moment()
            .startOf('day')
            .add(5, 'days')
            .add(21, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 22,
        },
      ],
    },
    soil_moisture: null,
    precipitation: {
      amount: 0,
      hourly: [
        {
          time: moment()
            .startOf('day')
            .add(5, 'days')
            .add(3, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 19,
        },
        {
          time: moment()
            .startOf('day')
            .add(5, 'days')
            .add(9, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 21,
        },
        {
          time: moment()
            .startOf('day')
            .add(5, 'days')
            .add(15, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 28,
        },
        {
          time: moment()
            .startOf('day')
            .add(5, 'days')
            .add(21, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          millimeters: 0,
          temperature: 22,
        },
      ],
    },
  },
]

const farmPrecipitation = {
  farmId: farms[0].id,
  farmName: farms[0].name,
  farmCreatedAt: moment().subtract(15, 'days').format('YYYY-MM-DD'),
  prec_mm_day: {
    [moment().startOf('day').subtract(9, 'days').format('YYYY-MM-DD HH:mm:ss')]:
      {
        id: 1,
        date: moment()
          .startOf('day')
          .subtract(9, 'days')
          .format('YYYY-MM-DD HH:mm:ss'),
        cons_mm: '0.0000',
      },
    [moment().startOf('day').subtract(8, 'days').format('YYYY-MM-DD HH:mm:ss')]:
      {
        id: 2,
        date: moment()
          .startOf('day')
          .subtract(8, 'days')
          .format('YYYY-MM-DD HH:mm:ss'),
        cons_mm: '0.0000',
      },
    [moment().startOf('day').subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss')]:
      {
        id: 3,
        date: moment()
          .startOf('day')
          .subtract(7, 'days')
          .format('YYYY-MM-DD HH:mm:ss'),
        cons_mm: '0.0000',
      },
    [moment().startOf('day').subtract(6, 'days').format('YYYY-MM-DD HH:mm:ss')]:
      {
        id: 4,
        date: moment()
          .startOf('day')
          .subtract(6, 'days')
          .format('YYYY-MM-DD HH:mm:ss'),
        cons_mm: '0.0000',
      },
    [moment().startOf('day').subtract(5, 'days').format('YYYY-MM-DD HH:mm:ss')]:
      {
        id: 5,
        date: moment()
          .startOf('day')
          .subtract(5, 'days')
          .format('YYYY-MM-DD HH:mm:ss'),
        cons_mm: '0.0000',
      },
    [moment().startOf('day').subtract(4, 'days').format('YYYY-MM-DD HH:mm:ss')]:
      {
        id: 6,
        date: moment()
          .startOf('day')
          .subtract(4, 'days')
          .format('YYYY-MM-DD HH:mm:ss'),
        cons_mm: '0.0000',
      },
    [moment().startOf('day').subtract(3, 'days').format('YYYY-MM-DD HH:mm:ss')]:
      {
        id: 7,
        date: moment()
          .startOf('day')
          .subtract(3, 'days')
          .format('YYYY-MM-DD HH:mm:ss'),
        cons_mm: '0.0000',
      },
    [moment().startOf('day').subtract(2, 'days').format('YYYY-MM-DD HH:mm:ss')]:
      {
        id: 8,
        date: moment()
          .startOf('day')
          .subtract(2, 'days')
          .format('YYYY-MM-DD HH:mm:ss'),
        cons_mm: '0.0000',
      },
    [moment().startOf('day').subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss')]:
      {
        id: 9,
        date: moment()
          .startOf('day')
          .subtract(1, 'days')
          .format('YYYY-MM-DD HH:mm:ss'),
        cons_mm: '0.0000',
      },
    [moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')]: {
      id: 10,
      date: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      cons_mm: '0.0000',
    },
  },
  obs_prec_mm_day: {
    [moment().startOf('day').subtract(9, 'days').format('YYYY-MM-DD HH:mm:ss')]:
      {
        date: moment()
          .startOf('day')
          .subtract(9, 'days')
          .format('YYYY-MM-DD HH:mm:ss'),
        observed_mm: null,
      },
    [moment().startOf('day').subtract(8, 'days').format('YYYY-MM-DD HH:mm:ss')]:
      {
        date: moment()
          .startOf('day')
          .subtract(8, 'days')
          .format('YYYY-MM-DD HH:mm:ss'),
        observed_mm: null,
      },
    [moment().startOf('day').subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss')]:
      {
        date: moment()
          .startOf('day')
          .subtract(7, 'days')
          .format('YYYY-MM-DD HH:mm:ss'),
        observed_mm: null,
      },
    [moment().startOf('day').subtract(6, 'days').format('YYYY-MM-DD HH:mm:ss')]:
      {
        date: moment()
          .startOf('day')
          .subtract(6, 'days')
          .format('YYYY-MM-DD HH:mm:ss'),
        observed_mm: null,
      },
    [moment().startOf('day').subtract(5, 'days').format('YYYY-MM-DD HH:mm:ss')]:
      {
        date: moment()
          .startOf('day')
          .subtract(5, 'days')
          .format('YYYY-MM-DD HH:mm:ss'),
        observed_mm: null,
      },
    [moment().startOf('day').subtract(4, 'days').format('YYYY-MM-DD HH:mm:ss')]:
      {
        date: moment()
          .startOf('day')
          .subtract(4, 'days')
          .format('YYYY-MM-DD HH:mm:ss'),
        observed_mm: null,
      },
    [moment().startOf('day').subtract(3, 'days').format('YYYY-MM-DD HH:mm:ss')]:
      {
        date: moment()
          .startOf('day')
          .subtract(3, 'days')
          .format('YYYY-MM-DD HH:mm:ss'),
        observed_mm: null,
      },
    [moment().startOf('day').subtract(2, 'days').format('YYYY-MM-DD HH:mm:ss')]:
      {
        date: moment()
          .startOf('day')
          .subtract(2, 'days')
          .format('YYYY-MM-DD HH:mm:ss'),
        observed_mm: null,
      },
    [moment().startOf('day').subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss')]:
      {
        date: moment()
          .startOf('day')
          .subtract(1, 'days')
          .format('YYYY-MM-DD HH:mm:ss'),
        observed_mm: null,
      },
    [moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')]: {
      date: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      observed_mm: null,
    },
  },
}

export { farmClima, farmPrecipitation, farmWeather, farms }
