import api from '@/services/api'

export const defensives = {
  namespaced: true,

  state: () => ({
    defensivesByCrop: [],
    defensivesLastValues: [],
  }),

  mutations: {
    setDefensivesByCrop(state, { cropId, defensives }) {
      const defensiveCrop = state.defensivesByCrop.find(
        (defensiveCrop) => defensiveCrop.cropId == cropId
      )

      if (defensiveCrop) {
        defensiveCrop.defensives = defensives
      } else {
        state.defensivesByCrop.push({ cropId, defensives })
      }
    },

    setDefensivesLastValues(state, { defensives }) {
      state.defensivesLastValues = Object.values(defensives)
    },
  },

  getters: {
    defensives(state, _, rootState) {
      const cropId = rootState.crops.currentCrop.id

      const defensivesFromCrop = state.defensivesByCrop.find(
        (defensives) => defensives.cropId == cropId
      )

      const defensives = defensivesFromCrop?.defensives ?? []

      if (!defensives.length) return []

      const clonedDefensives = [...defensives]

      return clonedDefensives?.map((defensive) => {
        const lastData = state.defensivesLastValues.find(
          (data) => data.defensivo_id == defensive.id
        )

        defensive.lastPrice = lastData?.preco ?? null
        defensive.lastDose = lastData?.dose ?? null

        return defensive
      })
    },
  },

  actions: {
    async fetchDefensives({ commit, rootState }, { farmId, programId }) {
      if (!farmId) throw new Error('Undefined farm')

      const cropId = rootState.crops.currentCrop?.id

      if (!cropId) throw new Error('Undefined current crop')

      const response = await api.defensivos.findByFazenda(
        farmId,
        cropId,
        programId
      )

      if (!response) throw new Error('Erro ao buscar defensivos')

      const payload = {
        defensives: response.data,
        cropId,
      }

      await commit('setDefensivesByCrop', payload)
    },

    async fetchDefensivesLastData({ commit }) {
      const response = await api.defensivos.findDefensivesLastValuesByAccount()

      if (!response) throw new Error('Erro ao buscar defensivos')

      const payload = {
        defensives: response.data,
      }

      await commit('setDefensivesLastValues', payload)
    },
  },
}
