import Cookies from 'js-cookie'

const cookieHandler = {
  get: (cookieName) => {
    return Cookies.get(cookieName)
  },
  set: (cookieName, value) => {
    Cookies.set(cookieName, value)
  },
  remove: (cookieName) => {
    Cookies.remove(cookieName)
  },
}

export default cookieHandler
