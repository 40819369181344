export default {
  namespaced: true,

  state: {
    hasWhiteMold: false,
  },
  getters: {
    hasProgramWithWhiteMold(state) {
      return state.hasWhiteMold
    },
  },
  mutations: {
    setHasWhiteMold(state, value) {
      state.hasWhiteMold = value
    },
  },
  actions: {
    verifyProgramsWithWhiteMold({ commit }, programs) {
      const programWhiteMold = programs.find(
        (program) => program.white_mold_enabled
      )

      commit('setHasWhiteMold', Boolean(programWhiteMold))
    },

    verifyProgramHasWhiteMold({ commit }, program) {
      commit('setHasWhiteMold', program?.white_mold_enabled)
    },
  },
}
