import { MAINTENANCE_MODE, UNAUTHORIZED } from './types/STATUS_CODE'
import store from '@/store'
import cookieHandler from './cookieHandler'

export default (statusCode) => {
  if (statusCode === MAINTENANCE_MODE) {
    store.dispatch('apiManager/toggleMaintenanceMode', true)
    return
  }

  const routeLogin =
    window.location.href === process.env.VUE_APP_BASE_URL + '/#/login'

  if (statusCode === UNAUTHORIZED && !routeLogin) {
    cookieHandler.remove('token')
    window.location.href = process.env.VUE_APP_BASE_URL
    return
  }

  store.dispatch('apiManager/toggleMaintenanceMode', false)
}
