import ndvi from './ndvi'
import user from './user'
import integration from './integration'
import sowings from './sowings'
import harvests from './harvests'
import seasonReport from './seasonReport'
import panels from './panels'
import sidebar from './sidebar'
import varieties from './varieties'
import programs from './programs'
import catalogModule from '@/modules/catalog/events/catalogModule'
import plansModule from '@/modules/plans/events/plansModule'
import plotModule from '@/modules/plot/events/plotModule'
import performanceModule from '@/modules/performance/events/performanceModule'
import notesModule from '@/modules/notes/events/notesModule'
import homeModule from '@/modules/home/events/homeModule'

export default {
  ndvi,
  user,
  integration,
  sowings,
  harvests,
  seasonReport,
  panels,
  sidebar,
  varieties,
  programs,
  catalogModule,
  plansModule,
  plotModule,
  performanceModule,
  notesModule,
  homeModule,
}
