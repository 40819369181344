export default {
  my_farm: ['img/my_farm1.png', 'img/my_farm2.png', 'img/my_farm3.png'],
  planting: ['img/planting1.png', 'img/planting2.png', 'img/planting3.png'],
  notes: ['img/notes1.png', 'img/notes2.png', 'img/notes3.png'],
  planning: ['img/planning1.png', 'img/planning2.png'],
  performance: ['img/performance1.png'],
  catalog: ['img/catalog1.webp'],
  mobile_my_farm: [
    'img/mobile-my_farm1.png',
    'img/mobile-my_farm2.png',
    'img/mobile-my_farm3.png',
  ],
  mobile_planting: [
    'img/mobile-planting1.png',
    'img/mobile-planting2.png',
    'img/mobile-planting3.png',
  ],
  mobile_notes: [
    'img/mobile-notes1.png',
    'img/mobile-notes2.png',
    'img/mobile-notes3.png',
  ],
  mobile_planning: ['img/mobile-planning1.png', 'img/mobile-planning2.png'],
  mobile_performance: ['img/mobile-performance1.png'],
  mobile_catalog: ['img/catalog1.webp'],
}
