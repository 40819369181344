import api from '@/services/api'
import cropStorage from '@/services/storage/cropStorage'
import { seasonsCrops } from './seasonsCrops'
import { CROP } from '@/utils/crops'

export const crops = {
  namespaced: true,

  state: {
    crops: [],
    currentCrop: null,
  },

  mutations: {
    setCrops(state, crops) {
      state.crops = crops
    },

    setCurrentCropId(state, cropId) {
      const currentCrop = state.crops.find((crop) => crop.id == cropId)

      if (!currentCrop) throw new Error('Invalid selected crop')

      state.currentCrop = currentCrop
      cropStorage(cropId).save()
    },
  },

  getters: {
    maxSprayingsByCurrentCrop: (state) => {
      const currentCropId = state?.currentCrop?.id
      if (currentCropId == CROP.SOYBEAN) {
        return process.env.VUE_APP_MAX_APLICACOES
      }
      if (currentCropId == CROP.WHEAT) {
        return process.env.VUE_APP_WHEAT_MAX_SPRAYINGS
      }
    },
    currentCrop: (state) => {
      return state.currentCrop
    },
    currentCropId: (state) => {
      return state.currentCrop?.id
    },
    isSoybean: (state) => {
      return state.currentCrop?.id == 1 ?? false
    },
    isWheat: (state) => {
      return state.currentCrop?.id == 2 ?? false
    },
  },

  actions: {
    async setCurrentCrop({ commit }, cropId) {
      commit('setCurrentCropId', cropId)
    },

    async fetchCrops({ commit }) {
      try {
        const { data } = await api.crops.all()
        commit('setCrops', data)
      } catch ($e) {
        throw new Error('Erro ao buscar crops')
      }
    },

    loadCurrentCropByStorage({ commit, state }) {
      const cropFromStorage = cropStorage().get()

      const cropExistsInAccount =
        state.crops.find((crop) => crop?.id == cropFromStorage) ?? false

      if (cropFromStorage && cropExistsInAccount) {
        commit('setCurrentCropId', cropFromStorage)
        return
      }

      commit('setCurrentCropId', seasonsCrops.state?.seasonsCrops[0]?.crop_id)
    },

    resetCurrentCrop({ dispatch }) {
      cropStorage().clear()
      dispatch('loadCurrentCropByStorage')
    },
  },
}
